"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRelevantComms = void 0;
const communities_1 = require("../communities");
/**
 * Looks up community where user has voting power
 */
const getRelevantComms = async (userAddress, provider, blockTag) => {
    const allVotes = await Promise.all(Array.from(communities_1.communities).map(async (comm) => {
        try {
            return await comm[1](userAddress, comm[0], blockTag, provider);
        }
        catch (e) {
            console.log(`Error resolving voting power for community ${comm[0]}: ${e}`);
            return 0;
        }
    }));
    // map community addresses to votes
    const nonZeroVoteCommunities = Array.from(communities_1.communities.keys())
        .map((address, index) => ({ [address]: allVotes[index] }))
        .filter(obj => Object.values(obj)[0] !== 0)
        .reduce((accumulator, current) => ({ ...accumulator, ...current }), {});
    return nonZeroVoteCommunities;
};
exports.getRelevantComms = getRelevantComms;
