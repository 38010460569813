"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.strategyForCommunity = void 0;
const communities_1 = require("../communities");
/**
 * Get strategy for community with address from /strategies dir
 */
const strategyForCommunity = (communityAddress) => {
    return communities_1.communities.get(communityAddress);
};
exports.strategyForCommunity = strategyForCommunity;
