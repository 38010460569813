"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseBlockTag = void 0;
/**
 * Attempt to resolve a blocktag into a number, if the tag isn't a number return undefined.
 */
const parseBlockTag = (blockTag) => {
    if (typeof blockTag === 'number' || typeof blockTag === 'undefined')
        return blockTag;
    let blockNumber = undefined;
    const parsedBlockTag = blockTag.includes('0x') ? parseInt(blockTag, 16) : parseInt(blockTag, 10);
    if (!isNaN(parsedBlockTag))
        blockNumber = parsedBlockTag;
    return blockNumber;
};
exports.parseBlockTag = parseBlockTag;
