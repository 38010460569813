"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaseInsensitiveMap = void 0;
class CaseInsensitiveMap extends Map {
    set(key, value) {
        return super.set(key.toLowerCase(), value);
    }
    get(key) {
        return super.get(key.toLowerCase());
    }
}
exports.CaseInsensitiveMap = CaseInsensitiveMap;
